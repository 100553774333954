ngx-slick-carousel {
	width: 100%;
	display: flex;
	align-items: center;

	.slick-list {
		// width: -webkit-fit-content;
		// width: -moz-fit-content;
		// width: fit-content;
		// margin: 0 auto;
		.slick-track {
			// max-width: -webkit-min-content;
			// max-width: -moz-min-content;
			// max-width: min-content;
			.slide.slick-slide {
				// width: -webkit-fit-content !important;
				// width: -moz-fit-content !important;
				// width: fit-content !important;
				max-width: 100%;

				&.slick-current {
				}
				&.slick-active {
				}
			}
		}
	}
}
