.dropdown-overlay {
	display: flex;
	width: 100%;
	height: 100%;

	.dropdown-overlay-background.opened {
		width: 100%;
		height: 100vh;
		position: fixed;
		left: 0;
		top: 0;
		z-index: 999;
	}

	.dropdown-container {
		position: relative;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;

		.dropdown-menu {
			position: absolute;
			height: 0;
			top: 100%;
			right: -115px;
			border-radius: 10px;
			overflow: hidden;

			&.statements {
				right: 0;
			}

			&.opened {
				display: flex;
				flex-direction: column;
				width: -webkit-fit-content;
				width: -moz-fit-content;
				width: fit-content;
				height: -webkit-fit-content;
				height: -moz-fit-content;
				height: fit-content;
				z-index: 999;
				background: var(--color-white-2);
				// padding: 10px;
				min-width: 150px;
				box-shadow: 5px 5px 6px 0 rgba(0, 0, 0, 0.1);
			}

			&.left {
				right: 0px;
			}

			&.top-left-position {
				right: 103%;
				top: unset;
				margin-right: 6px;
			}

			.dropdown-item {
				padding: 8px;
				background-color: var(--color-white-2);
				white-space: nowrap;
				cursor: pointer;

				&:hover {
					background-color: var(--color-select-blue);
				}
			}
		}
	}
}
ng-dropdown-panel.ng-dropdown-panel {
	border: 1px solid var(--color-white-3) !important;
	border-bottom-left-radius: 8px !important;
	border-bottom-right-radius: 8px !important;
	overflow: hidden;
}
