/* You can add global styles to this file, and also import other style files */
@use 'scss-reset/reset';
@use '@ng-select/ng-select/scss/default.theme';
@use '@ng-select/ng-select/scss/ng-select.component';
@import 'ngx-toastr/toastr';
@import 'assets/fonts/poppins/stylesheet';
@import './styles/index';
@import 'swiper/css';
@import '@angular/cdk/overlay-prebuilt.css';
@import './node_modules/@perfectmemory/ngx-contextmenu/src/assets/stylesheets/base.scss';
@import '@danielmoncada/angular-datetime-picker/assets/style/picker.min.css';

html,
body {
	-ms-scroll-chaining: none;
	overscroll-behavior: none;
	overflow: auto;

	&:focus-within {
		overflow: auto;
	}
}

a {
	text-decoration: none;
}

* {
	&::-webkit-scrollbar {
		width: 4px;
		height: 4px;
	}

	&::-webkit-scrollbar-track {
		box-shadow: none;
		background-color: var(--color-white-2);
		border-radius: 2px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--color-white-3);
		border-radius: 2px;
	}
}

.no-scrollbar {
	&::-webkit-scrollbar {
		width: 0 !important;
		height: 0 !important;
	}

	&::-webkit-scrollbar-track {
		box-shadow: none;
		border-radius: 0;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 0;
	}
}

body {
	font-family: var(--font-family);
	overflow-y: hidden;
	height: 100vh;
}

.text {
	&-header {
		font-size: 48px;
		font-weight: 600;
		line-height: 72px;
	}

	&-base {
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
	}

	&-link {
		font-size: 18px;
		font-weight: 600;
		line-height: 22px;
	}
}

.feed {
	&-layout {
		display: flex;
		flex-wrap: wrap;
		flex-grow: 1;
		width: 100%;
		min-height: 100vh;

		@media screen and (min-width: 1340px) {
			flex-wrap: nowrap;
		}
	}

	&-box {
		width: 100%;
		min-height: 100vh;

		@media screen and (min-width: 1024px) {
			max-width: var(--feed-width);
			width: var(--feed-width);
			border-left: 1px solid var(--color-main-border);
			border-right: 1px solid var(--color-main-border);
		}
	}

	&-aside {
		display: block;
		width: var(--aside-width);

		@media screen and (min-width: 1340px) {
			display: block;
		}
	}

	&-content {
		padding: 24px;
		margin-bottom: 60px;

		@media screen and (min-width: 1024px) {
			margin-bottom: 0;
		}
	}

	&-component-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 20px 24px;
		border-bottom: 1px solid var(--color-white-2);
		gap: 8px;

		h2 {
			display: inline-block;
			flex: 1 0 0;
			margin-right: 20px;
			font-size: 20px;
			font-weight: 500;
		}

		app-icons[ng-reflect-name='arrow'] {
			margin-right: 20px;
		}
	}
}

.single-child-page {
	max-width: 650px;
	padding: 24px;
	margin: 0 auto;

	h1 {
		font-size: 36px;
		font-weight: 600;
		line-height: 54px;
		text-align: center;
		color: var(--color-purple);
	}

	p {
		font-size: 18px;
		font-style: italic;
		font-weight: 600;
		line-height: 27px;
		text-align: center;
	}
}

#main {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: center;
	margin: 0 auto;
	max-width: 100%;
	height: 100%;

	@media screen and (min-width: 1024px) {
		max-width: calc(var(--aside-width) + var(--content-width));
		flex-wrap: nowrap;
		transition: 0.3s;
	}
}

.feed-aside {
	display: none;
	position: relative;
	// z-index: 2;
	max-width: var(--aside-width);
	min-width: var(--aside-width);
	width: 100%;
	height: 100%;

	.aside-content {
		display: flex;
		flex-direction: column;
		width: var(--aside-width);
		height: 100%;
		overflow: auto;
		gap: 16px;
		position: fixed;
		padding: 24px;
	}

	@media screen and (min-width: 1340px) {
		display: flex;
	}
}

.divider {
	display: block;
	width: 100%;
	height: 1px;
	background-color: var(--color-main-border);
}

.flex-column {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.screen-aside {
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	border-left: 1px solid var(--color-white-3);
	border-right: 1px solid var(--color-white-3);
	height: 100vh;
	min-height: 100%;

	&-list {
		border-right: 1px solid var(--color-white-3);
		width: 100%;

		@media screen and (min-width: 1024px) {
			width: 40%;
		}

		&-header {
			padding: 15px 24px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-bottom: 1px solid var(--color-white-3);

			span {
				font-weight: 500;
				font-size: 20px;
				line-height: 30px;
			}
		}

		&-actions {
			display: flex;

			button {
				margin-left: 8px;
			}
		}
	}

	&-content {
		width: 60%;
	}
}

.cover-fit {
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
	-o-object-position: center center;
	object-position: center center;
}

.spacer {
	&-w4 {
		width: 4px;
	}

	&-w8 {
		width: 8px;
	}

	&-w10 {
		width: 10px;
	}

	&-w12 {
		width: 12px;
	}

	&-w16 {
		width: 16px;
	}

	&-h4 {
		height: 4px;
	}

	&-h8 {
		height: 8px;
	}

	&-h10 {
		height: 10px;
	}

	&-h12 {
		height: 12px;
	}

	&-h16 {
		height: 16px;
	}

	&-flex {
		flex: 1 0 0;
	}
}

#content {
	margin: 0;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	min-height: 100vh;
	overflow-y: auto;
	flex: 1;
	transition: 0.3s;

	#feed {
		display: flex;
		flex-direction: column;
		gap: 20px;
		flex-grow: 1;
		width: 100%;
		max-width: 650px;
		margin: 0 auto;
		padding: 75px 10px 100px 10px;
		min-height: 100vh;
		&.looks-feed {
			background-color: unset;
			border: none;
			padding: 0;
			max-width: 100%;
		}
	}

	@media screen and (min-width: 1024px) {
		margin: 0;
		max-width: var(--content-width);
		max-height: 100vh;
		overflow: unset;

		#feed {
			padding: 27px 10px;
		}
	}

	@media screen and (min-width: 1340px) {
		#feed {
			border-left: 1px solid var(--color-white-2);
			border-right: 1px solid var(--color-white-2);
			background-color: var(--color-feed-background);
			padding: 27px 18px;
			&.looks-feed {
				background-color: unset;
				padding: 0;
				width: 500px; //
				max-width: 100%;
			}
		}
	}
}

.alert-component {
	display: flex;
	align-items: center;
	gap: 11px;
	border-radius: 8px;
	border: 1px solid var(--color-main-border);
	padding: 8px;
	color: var(--color-purple);
}

.overflow-visible {
	overflow: visible !important;
}

.float-left {
	margin-left: auto;
}

.desktop-visible {
	display: none;
}

@media screen and (min-width: 1024px) {
	#main.looks-page {
		max-width: 100%;
		#content {
			max-width: 100%;
			transition: 0.3s;
		}
	}
}

@media screen and (min-width: 1340px) {
	body #main .mobile-tablet-view-only {
		display: none;
	}
}

@media screen and (min-width: 1340px) {
	.desktop-visible {
		display: flex;
	}
}

.flex-spacer {
	flex: 1 0 0;
}

.cookie-dialog {
	&-container {
		.cdk-dialog-container {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
		}
	}
}

.capitalize {
	text-transform: capitalize;
}

.uppercase {
	text-transform: uppercase;
}

.custom-dialog-class {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10;
}
