// .custom-slider .ngx-slider .ngx-slider-bar {
//   background: #ffe4d1;
//   height: 2px;
// }
.custom-slider {
  .ngx-slider {
    margin: 18px 0 15px;
    .ngx-slider-pointer {
      width: 21px;
      height: 21px;
      top: -10px;

      background-color: var(--color-purple);
      box-shadow: 0px 4px 4px 0px #00000040;
      border: 4px solid #FFFFFF;


      &:after {
        display: none;

      }
    }

    .ngx-slider-tick {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      margin: 0;
      background-color: #D9D9D9;
      top: -2px;
      &:last-child {
        margin-left: 6px;
      }
    }

    .ngx-slider-selection {
      background-color: var(--color-purple);
    }
    .ngx-slider-bubble {
      display: none;
    }
  }
}
