.dialog-overlay {
	position: fixed;
	z-index: 999;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	display: none;
	padding: 16px;

	&.opened {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&-background {
		position: absolute;
		z-index: 999;
		background: rgb(0 0 0 / 0.25);
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	.cropper-area {
		display: flex;
		flex-direction: column;
		gap: 8px;

		&.cropper-area-avatar {
			.cropper-result {
				align-self: center;
				max-width: 300px;
			}
		}

		.form-submit {
			display: flex;
			gap: 8px;
		}
	}
}

.compact {
	.app-dialog {
		max-width: 580px;
	}
}

.app-dialog {
	position: relative;
	overflow: auto;
	z-index: 999;
	max-width: 650px;
	max-height: 100%;
	height: auto;
	width: 100%;
	// margin: 16px;
	border: 1px solid var(--color-white-2);
	border-radius: 10px;
	box-shadow: 0 0 10px 0 rgb(0 0 0 / 0.25);
	padding: 12px;
	cursor: default;

	&::-webkit-scrollbar {
		width: 4px;
	}

	&::-webkit-scrollbar-track {
		box-shadow: none;
		background-color: var(--color-white-2);
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
		margin: 16px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--color-white-3);
		border-radius: 2px;
	}

	&-large {
		max-width: 990px;
		height: 90vh;
		margin: 0;
		padding: 0;
		overflow: hidden;

		app-icons {
			position: static !important;
		}
	}

	&.clear-padding {
		border: none;
		padding: 0;
	}

	.dialog {
		&-content {
			display: flex;
			flex-direction: column;
			gap: 8px;
		}

		&-header {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			padding: 4px 0 8px 0;
			border-bottom: 1px solid var(--color-main-border);
		}

		&-body {
			display: flex;
			flex-direction: column;
			gap: 8px;
		}
	}

	app-icons.close-icon {
		position: absolute;
		top: 10px;
		right: 16px;
	}

	.dialog {
		display: flex;
		flex-direction: column;
		gap: 8px;

		&-controls {
			display: flex;
			gap: 8px;
		}
	}
}

.dialog-background {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgb(0 0 0 / 0.25);
	z-index: 1000;

	.app-dialog {
		background-color: var(--color-white);
		max-width: -webkit-fit-content;
		max-width: -moz-fit-content;
		max-width: fit-content;
	}
}

@media screen and (min-width: 1024px) {
	.app-dialog {
		margin: 16px;
		padding: 16px;

		.dialog {
			&-header {
				padding: 4px 0 20px 0;
			}
		}
	}
}

.overflow-visible:not(.app-dialog) {
	.app-dialog {
		overflow: visible;
	}
}
