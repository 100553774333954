.btn {
	border-radius: 8px;
	border: none;
	transition: 300ms ease-in;
	&-primary {
		background-color: var(--color-purple);
		color: var(--color-white);
		padding: 16px;
		font-weight: 500;
		font-size: 18px;
		line-height: 27px;
		@media (hover: hover) {
			&:hover {
				background-color: #947bff;
			}
		}
		@media (hover: none) {
			&:active {
				background-color: #947bff;
			}
		}
		&:disabled {
			background-color: var(--color-white-3);
			color: var(--color-text-gray);
		}
		&.btn-subscribed {
			background-color: #dedeeb;
			color: var(--color-purple);
		}
	}

	&-raised-basic {
		background-color: var(--color-white);
		color: var(--color-black);
		border-radius: 34px;
		padding: 12px;
		font-weight: 500;
		font-size: 14px;
		line-height: 21px;
		@media (hover: hover) {
			&:hover {
				background-color: #dedeeb;
			}
		}
	}
	&-light {
		background-color: var(--color-white);
		color: var(--color-purple);
		padding: 13px 16px;
		font-weight: 500;
		font-size: 18px;
		line-height: 27px;
		@media (hover: hover) {
			&:hover {
				background-color: var(--color-white-2);
			}
		}
		@media (hover: none) {
			&:active {
				background-color: var(--color-white-2);
			}
		}
		&:disabled {
			background-color: var(--color-white-3);
			color: var(--color-text-gray);
		}
	}

	&-purchase {
		font-size: 16px;
		line-height: 24px;
		border-radius: 25px;
		padding: 8px 16px;

		@media screen and (min-width: 375px) {
			padding: 16px 16px;
		}
	}

	&-secondary {
		background-color: var(--color-secondary);
		color: var(--color-black);
		padding: 16px;
		font-weight: 500;
		font-size: 18px;
		line-height: 27px;
	}

	&-upload {
		border-radius: 8px;
		padding: 10px 40px;
		background: var(--color-white);
		font-size: 16px;
		font-weight: 500;
		display: flex;
		align-items: center;
		justify-items: center;
		app-icons {
			margin-right: 8px;
		}
	}

	&-large-upload {
		width: 50px;
		height: 130px;
		background: var(--color-white-2);
	}

	&-send {
		border-radius: 10px;
		font-weight: 600;
		font-size: 14px;
		line-height: 21px;
		padding: 9.5px 15px;
		background: var(--color-black);
		color: var(--color-white);
		&:disabled {
			background: var(--color-text-gray);
			color: var(--color-white-3);
		}
	}

	&-icon {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		background: var(--color-white-2);
		&-square {
			width: 50px;
			height: 50px;
			border-radius: 10px;
		}
		&:hover {
			background: var(--color-white-3);
		}
	}

	&-xs-icon {
		width: 14px;
		height: 14px;
	}

	&-s-icon {
		width: 20px;
		height: 20px;
	}

	&-m-icon {
		width: 30px;
		height: 30px;
	}

	&-icon-count {
		padding: 0;
		border-radius: 0;
		background: 0;
		font-weight: 500;
		font-size: 14px;
		line-height: 21px;
		display: flex;
		app-icons {
			display: inline-flex;
			margin-right: 8px;
		}
	}

	&-round {
		border-radius: 50%;
	}

	&-square {
		border-radius: 10px;
	}

	&-full-width {
		width: 100%;
	}

	&-range {
		border-radius: 10px;
	}

	&-standart {
		border-radius: 10px;
	}

	&-nav {
		border-radius: 10px;
		padding: 13px 16px;
	}

	&-regular-pop-up {
		padding: 12px 20px;
		background: #6a49fa;
	}

	&-47br {
		border-radius: 47px;
	}

	&-range-m {
		background-color: var(--color-purple);
		color: var(--color-white);
		padding: 12px;
		border-radius: 64px;
		width: 100%;
	}

	&-control {
		border-radius: 10px;
		width: 28px;
		height: 28px;
		background-color: var(--color-white);
	}

	&-control-n {
		border-radius: 10px;
		padding: 6px 6px;
		background-color: var(--color-white-20);
	}

	&-filter-btn {
		display: flex;
		align-items: center;
		gap: 8px;
		padding: 8px;
		border: 1px solid var(--color-main-border);

		@media (hover: hover) {
			&:hover {
				background-color: var(--color-white-2);
			}
		}
		@media (hover: none) {
			&:active {
				background-color: var(--color-white-2);
			}
		}

		&.selected {
			border: 1px solid var(--color-purple);
			background-color: var(--color-purple);
			color: var(--color-white);
		}
	}

	&.contrast-color {
		background-color: var(--color-white-2);
	}

	&-regular {
		height: 50px;
		border-radius: 10px;
		padding: 10px 8px;
		display: flex;
		align-items: center;
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
		letter-spacing: 0;
	}

	&-color-purple {
		background-color: var(--color-purple);
		color: var(--color-white);
		@media (hover: hover) {
			&:hover {
				background-color: #947bff;
			}
		}
		@media (hover: none) {
			&:active {
				background-color: #947bff;
			}
		}
		&.disabled {
			background-color: var(--color-white-3);
			color: var(--color-text-gray);
		}
	}

	&-color-grey {
		background-color: var(--color-white-2);
		color: var(--color-black);
		@media (hover: hover) {
			&:hover {
				background-color: var(--color-white-3);
			}
		}
		@media (hover: none) {
			&:active {
				background-color: var(--color-white-3);
			}
		}
		&.disabled {
			background-color: var(--color-white-3);
			color: var(--color-text-gray);
		}
	}

	&:disabled {
		cursor: default;
		opacity: 0.7;
	}
}
