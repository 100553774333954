.content-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 16px;
  border-bottom: 1px solid var(--color-main-border);
  gap: 5px;
  min-height: 50px;
  cursor: default;

  @media screen and (min-width: 768px) {
    min-height: 81px;
  }

  &.between {
    justify-content: space-between;
  }

  app-icons {
    transform: scale(0.75);
  }

  .btn-nav {
    min-width: 138px;
  }

  .content-name {
    flex-grow: 1;
  }
}

.content-body {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px 24px;
}

.control-body {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px 24px 16px 24px;
}

@media screen and (min-width: 1024px) {
  .content-header {
    padding: 10px 24px;

    app-icons {
      transform: unset;
    }

    .control-icon {
      transform: scale(1.41);
      margin-right: 8px;
    }
  }
}
