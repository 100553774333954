input,
textarea {
  display: block;
  width: 100%;
  background-color: var(--color-white-2);
  border: 1px solid var(--color-white-2);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.08px;
  transition: border 300ms ease-in;

  &:focus {
    border: 1px solid var(--color-purple);
  }

  &.ng-invalid.ng-dirty.ng-touched {
    border-color: var(--color-error);
  }

  &::-webkit-input-placeholder {
    color: var(--color-text-gray);
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.08px;
  }

  &::-moz-placeholder {
    color: var(--color-text-gray);
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.08px;
  }

  &:-ms-input-placeholder {
    color: var(--color-text-gray);
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.08px;
  }

  &::-ms-input-placeholder {
    color: var(--color-text-gray);
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.08px;
  }

  &::placeholder {
    color: var(--color-text-gray);
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.08px;
  }

  &[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 22px;
    height: 22px;
    border: 4px solid var(--color-purple);
    border-radius: 8px;
    margin: 0;

    &:checked {
      -webkit-appearance: auto;
      -moz-appearance: auto;
      appearance: auto;
    }

    &:disabled {
      border-color: var(--color-text-gray);
    }
  }

  &.contrast {
    background-color: var(--color-white);
  }
}

textarea {
  height: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: none;
    background-color: var(--color-white-2);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    margin: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-white-3);
    border-radius: 2px;
  }
}

.ng-select.ng-select-single {
  .ng-select-container {
    height: 50px;
    flex-wrap: wrap;
    align-items: center;
    background-color: var(--color-white-2);
    border: 1px solid var(--color-white-2);

    .ng-value {
      font-weight: 500;
    }
  }
}

.ng-select .ng-select-container .ng-value-container .ng-input>input {
  height: 42px;
  margin: 0;
}

.input-box {
  position: relative;

  &.prefix {
    input {
      padding-left: 45px;
    }

    .prefix-icon {
      position: absolute;
      left: 16px;
      top: 11px;
    }
  }
}

.input-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;

  .input-container {
    width: 100%;

    @media screen and (min-width: 1024px) {
      flex: 1 0 0;
    }
  }
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-grow: 1;

  input {
    height: 50px;
    margin: 0;

    &.ng-invalid.ng-dirty.ng-touched,
    &.invalid {
      border-color: var(--color-error);
    }

    &.dropdown-list {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .label,
  label {
    min-height: 27px;
  }

  .hint-wrapper {
    display: flex;
    flex-direction: row;

    .letter-counter {
      margin-left: auto;
      color: var(--color-text-gray);
    }

    .error-message {
      color: var(--color-error);
    }
  }

  .prefix-container {
    position: relative;

    .prefix {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 16px;
      width: 15px;
      height: 100%;

      &.prefix-icon {
        left: 12px;
      }
    }

    input {
      padding-left: 30px;
    }
  }

  .suffix-container {
    position: relative;

    .suffix {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      right: 0px;
      width: 50px;
      height: 50px;
    }

    input {
      padding-right: 45px;
    }

    .hint-wrapper {
      margin-top: 8px;
    }
  }

  .checkbox-content-item {
    display: flex;
    align-items: center;

    .label-wrapper {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }

    input {
      height: 22px;

      &[type="checkbox"] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 22px;
        height: 22px;
        min-width: 22px;
        border: 4px solid var(--color-purple);
        border-radius: 8px;
        margin: 0;

        &:checked {
          -webkit-appearance: auto;
          -moz-appearance: auto;
          appearance: auto;
        }
      }
    }
  }
}

.checkbox-container {
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;

  label {
    font-weight: 500;
    flex: 1 0 0;
  }

  input {
    margin-right: 8px;
  }

  span {
    flex: 1 0 0;
  }
}
