.slide-toggle {
	position: relative;
	display: inline-block;
	width: 50px;
	height: 30px;
}

.slide-toggle input {
	opacity: 0;
	width: 0;
	height: 0;
}

.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: var(--color-toggle-unchecked);
	transition: 0.4s;
	border-radius: 30px;
}

.slider:before {
	position: absolute;
	content: '';
	height: 22px;
	width: 22px;
	left: 4px;
	bottom: 4px;
	background-color: var(--color-toggle-slider);
	transition: 0.4s;
	border-radius: 50%;
}

input:checked + .slider {
	background-color: var(--color-toggle-checked);
}

input:focus + .slider {
	box-shadow: 0 0 1px var(--color-toggle-checked);
}

input:checked + .slider:before {
	transform: translateX(20px);
}

.toogle-wrapper {
	display: flex;
	justify-content: space-between;
	padding: 15px 0;
}

.overlay-container-active {
	.swiper-container .blurhash-container img.loaded-image {
		-o-object-fit: contain;
		object-fit: contain;
	}
}
