.font-main-large {
	font-size: 20px;
	font-weight: 600;
	line-height: 30px;
}

.font-main-small {
	font-size: 18px;
	font-weight: 500;
	line-height: 27px;
}

.font-main-extra-small {
	font-size: 12px;
	font-weight: 500;
	line-height: 100%;
}

.font-content-large {
	font-size: 18px;
	font-weight: 500;
	line-height: 24px;
}

.font-content-middle {
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
}

.font-content-small {
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
}

.font-content-extra-small {
	font-size: 11px;
	font-weight: 600;
	line-height: 16.5px;
}

.font-static-extra-small {
	font-size: 12px;
	font-weight: 400;
	line-height: 12px;
}

.font-static-extra-small-2 {
	font-size: 11px;
	font-weight: 500;
	line-height: 15px;
}

.font-static-extra-small-3 {
	font-size: 10px;
	font-weight: 000;
	line-height: 12px;
}

.profile-name {
	font-size: 20px;
	font-weight: 600;
	line-height: 30px;
	letter-spacing: 0;
}

.profile-nickname {
	font-size: 14px;
	line-height: 21px;
	font-weight: 400;
}

.text {
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	letter-spacing: 0;
}

.attention-text {
	font-size: 12px;
	font-weight: 500;
	line-height: 18px;
	letter-spacing: 0;
}

.post-name {
	font-size: 14px;
	font-weight: 600;
	line-height: 21px;
	letter-spacing: 0;
}

.post-nickname {
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
}

.secondary-text {
	font-size: 12px;
	font-weight: 500;
	line-height: 18px;
}

.extra-large {
	font-size: 24px;
	font-weight: 600;
	line-height: 45px;
}

.label-font {
	font-size: 14px;
	font-weight: 500;
	line-height: 21px;
}

@media screen and (min-width: 1024px) {
	.font-main-large {
		font-size: 36px;
		line-height: 46px;
	}

	.font-main-small {
		font-size: 20px;
		font-weight: 500;
		line-height: 30px;
	}

	.font-main-extra-small {
		font-size: 14px;
	}

	.font-content-large {
		font-size: 24px;
		line-height: 36px;
	}

	.font-content-middle {
		font-size: 16px;
		line-height: 24px;
	}

	.font-content-small {
		font-size: 14px;
		line-height: 21px;
	}

	.font-content-extra-small {
		font-size: 14px;
		line-height: 21px;
	}

	.profile-name {
		font-size: 24px;
		line-height: 36px;
	}

	.profile-nickname {
		font-size: 16px;
		line-height: 24px;
	}

	.text {
		font-size: 16px;
		line-height: 24px;
	}

	.attention-text {
		font-size: 14px;
		line-height: 21px;
	}

	.post-name {
		font-size: 16px;
		line-height: 24px;
	}

	.post-nickname {
		font-size: 14px;
		line-height: 21px;
	}

	.extra-large {
		font-size: 40px;
	}
}

.extra-small {
	font-size: 12px;
	font-weight: 600;
	line-height: 13px;
}

.main-header {
	font-size: 48px;
	font-weight: 600;
	line-height: 72px;
	letter-spacing: 0;
}

.header-l {
	font-size: 20px;
	font-weight: 500;
	line-height: 30px;
	letter-spacing: 0;
}

.header {
	font-size: 16px;
	font-weight: 600;
	line-height: 19px;
	letter-spacing: 0;
}

.header-s {
	font-size: 16px;
	font-weight: 500;
	line-height: 18px;
	letter-spacing: 0;
}

.secondary-header {
	font-size: 15px;
	font-weight: 700;
	line-height: 23px;
	letter-spacing: 0;
}

.action-text {
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: 0;
}

.link {
	font-size: 12px;
	font-weight: 500;
	line-height: 18px;
	letter-spacing: 0;
}

.hint {
	font-size: 13px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0;
}

.dialog-title {
	font-size: 24px;
	font-weight: 500;
	line-height: 36px;
	letter-spacing: 0;
}

.dialog-header {
	font-size: 24px;
	font-weight: 500;
	line-height: 36px;
	letter-spacing: 0;
}

.post-name {
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
	letter-spacing: 0;
}

.nickname,
.text-post {
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	letter-spacing: 0;
}

.price-s {
	font-size: 14px;
	font-weight: 500;
	line-height: 21px;
	letter-spacing: 0;
}

.price-xs {
	font-size: 12px;
	font-weight: 500;
	line-height: 18px;
	letter-spacing: 0;
}

.input-label {
	font-size: 18px;
	font-weight: 400;
	line-height: 27px;
}

.text-color-main {
	color: var(--color-black);
}

.text-color-secondary {
	color: var(--color-text-gray);
}

.text-color-primary {
	color: var(--color-primary-button-hovered);
}

.text-color-purple,
.highlighted {
	color: var(--color-purple);
}

.text-color-light {
	color: var(--color-white);
}

.text-regular {
	font-weight: 400;
}

.text-medium {
	font-weight: 500;
}

.text-bold {
	font-weight: 600;
}

a {
	cursor: pointer;
}
