:root {
	--feed-width: 650px;
	--aside-width: 340px;
	--content-width: calc(var(--feed-width) + var(--aside-width));
	--content-padding: 24px;

	--font-family: 'Poppins', sans-serif;

	--color-green: #5bce90;
	--color-purple: #6a49fa;
	--color-purple-2: #9747ff;
	--color-light-purple: #b19eff;
	--color-black: #131315;
	--color-white: #fff;
	--color-white-2: #f5f5f5;
	--color-white-3: #dedeeb;
	--color-white-4: #bdbde5;
	--color-white-20: rgba(255, 255, 255, 0.2);
	--color-white-70: rgba(255, 255, 255, 0.7);
	--color-text-gray: #929298;
	--color-select-blue: #e2eaff;
	--color-error: #eb5757;

	--color-secondary: #dedeeb;
	--color-main-border: #dedeeb;
	--color-primary-button-hovered: #947bff;

	--color-toggle-checked: #6a49fa;
	--color-toggle-unchecked: #dedeeb;
	--color-toggle-slider: #ffffff;

	--color-feed-background: #eadff6;

	--color-opacity-background: #00000033;
	--shadow: 0px 4px 20px 0px #0000000d;
	--contrast-shadow: 0 4px 8px rgb(0 0 0 / 61%), 0 6px 20px rgb(0 0 0 / 50%), 0 8px 24px rgb(255 255 255 / 30%);

	--desktop-width: 1340px;
	--desktop-xs-width: 1024px;
	--tablet-width: 762px;
	--mobile-width: 320px;
	--max-user-background-image-width: 650px;
}
