.notification, .app-toast {
  padding: 8px 24px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 1px solid var(--color-white-3);
  min-height: 90px;
  &_status {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background: var(--color-purple);
    margin-right: 19px;
    &.isRead {
      background: var(--color-white-3);
    }
  }
  app-profile-avatar {
    margin-right: 16px;
  }
  &_body {
    flex: 1 0 0;
    display: flex;
    flex-direction: column;
    align-items: start;
    font-size: 16px;
    line-height: 24px;
    &-text {
      display: flex;
      flex-wrap: nowrap;
      align-items: start;
      app-icons {
        margin: 2px 8px 2px 0;
      }
    }
    &-date {
      display: flex;
      align-items: center;
      gap: 8px;
      color: var(--color-text-gray);
      app-icons {
        cursor: pointer;
      }
    }
  }
  &_delete {
    margin-left: 16px;
    cursor: pointer;
  }
  a, &_link {
    font-weight: 500;
    color: var(--color-purple);
  }
  &_type {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background: var(--color-white-2);
    border: 1px solid var(--color-white-3);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
  }

  &.toast {
    border-radius: 10px;
    background: var(--color-white);
    border: 1px solid var(--color-white-2);
    padding: 16px;
    margin: 16px;
    width: 500px;
    max-width: 100%;
    box-shadow: 0 10px 10px 0 rgb(0,0,0,.25);
  }

  app-profile-avatar, app-user-name {
    cursor: pointer;
  }

  &.toast {
    &-success {
      background-color: var(--color-green);
      color: var(--color-white);
    }
    &-error {
      background-color: var(--color-error);
      color: var(--color-white);
    }
    &-info {
      background-color: var(--color-purple);
      color: var(--color-white);
    }
  }
}
.notification-toast {
  pointer-events: auto;
}
.notification {
  padding: 8px 10px;
  &_status {
    margin-right: 8px;
    
  }
  &_delete {
    margin-left: 8px;
    cursor: pointer;
  }
  app-profile-avatar {
    margin-right: 8px;
  }
}
.app-toast {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 10px;
  background-color: var(--color-white);
  border: 1px solid var(--color-white-2);
  padding: 16px 16px 16px 48px;
  margin: 16px;
  width: 320px;
  max-width: 100%;
  min-height: 70px;
  box-shadow: 0 10px 10px 0 rgb(0,0,0,.25);
  background-position: 16px 50%;
  background-size: 24px;
  background-repeat: no-repeat;
}

@media screen and (min-width: 1024px) {
  .notification {
    padding: 8px 24px;
    &_status {
      margin-right: 19px;
      
    }
    &_delete {
      margin-left: 16px;
    }
    app-profile-avatar {
      margin-right: 16px;
    }
  }
}
