.ng-select {
  .ng-select-container {
    border-radius: 8px !important;
  }
  .ng-value-container {
    height: 50px;
  }

  span.ng-arrow-wrapper .ng-arrow {
    display: inline-block;
    position: relative;
    width: 7px;
    height: 7px;
    border: none;
    border-color: unset;
    border-style: unset;
    border-width: unset;
    padding: 3px;
    transition: transform 0.3s ease;

    &::after,
    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 2px;
      height: 8px;
      background-color: black;
      border-radius: 4px;
      transition: 0.3s;
    }

    &::before {
      transform: rotate(45deg);
      top: 0;
      right: 0;
    }

    &::after {
      transform: rotate(-45deg);
      top: 0;
      left: 1px;
    }
  }

  &.ng-select-opened span.ng-arrow-wrapper .ng-arrow {
    &::before {
      transform: rotate(135deg);
      top: 0;
      right: 0;
    }

    &::after {
      transform: rotate(-135deg);
      background-color: black;
      top: 0;
      left: 1px;
    }
  }
}

body .ng-dropdown-panel.ng-select-bottom {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;

  .ng-dropdown-panel-items {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;

    &::-webkit-scrollbar-track {
      margin: 4px;
    }

    .ng-option {
      background-color: transparent;

      &:hover {
        background-color: #ebf5ff;
      }

      &:last-child {
        border-radius: 0;
      }
    }
  }

  .ng-option:not(:last-child) {
    border-bottom: 1px solid var(--color-main-border);
  }

  .ng-dropdown-footer {
    padding: 0;

    &:hover {
      background-color: var(--color-select-blue);
    }
  }
}

// For card optins. same for ng-value && ng-option
.card-option {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  gap: 8px;
  padding: 15px;

  app-icons {
    position: relative;
    top: unset;
    right: unset;
  }
}

.option-content, .label-content {
  display: flex;
  gap: 8px;
  img {
    width: 20px;
    height: 20px;
  }
}
.country-option, .select-option {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  app-icons {
    position: relative;
    top: unset;
    right: unset;
  }
}
