.page {
  display: flex;
  flex: 1;

  .section-feed {
    flex-grow: 1;
    border-left: 1px solid var(--color-white-2);
    border-right: 1px solid var(--color-white-2);
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
}

